"use client";

import { useEffect, useRef, useState } from "react";
import { NavProps } from "../types";

const stickyClasses = [
  "md:fixed",
  "md:top-0",
  "md:left-0",
  "md:right-0",
  "md:z-50",
  "md:shadow-md",
  "md:border-b",
  "md:border-gray-100",
];

export default function MastersNav({ data, applyUrl }: NavProps) {
  const [activeLink, setActiveLink] = useState<string>("");
  const pageNavbar = useRef<HTMLDivElement | null>(null);
  const initialOffset = useRef<number | null>(null);
  const navHeight = 88;

  useEffect(() => {
    const navbar = pageNavbar.current;
    const handleScroll = () => {
      if (navbar) {
        if (initialOffset.current === null) {
          initialOffset.current = navbar.offsetTop;
        }
        const isSticky = window.scrollY >= initialOffset.current;

        if (isSticky) {
          navbar.classList.add(...stickyClasses);
        } else {
          navbar.classList.remove(...stickyClasses);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: string,
  ) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: sectionTop - navHeight,
        behavior: "smooth",
      });
      setActiveLink(id);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <nav ref={pageNavbar} className="bg-white">
      <div className="container flex flex-col md:flex-row md:justify-between md:items-center px-6 mx-auto w-full">
        <div className="flex flex-col md:justify-between lg:justify-start md:flex-row md:text-base w-full">
          <span className="px-2 py-2 font-bold md:hidden">
            Jump To section:
          </span>
          {data &&
            data.map(({ id, name }) => (
              <a
                key={id}
                href={`#${id}`}
                className={`px-2 py-2 lg:mr-6 md:py-7 text-info-500 md:font-semibold md:border-b-4 hover:underline ${activeLink === id ? "border-info-500" : "border-transparent"}`}
                onClick={(e) => handleLinkClick(e, id)}
              >
                {name}
              </a>
            ))}
        </div>
        <div className="hidden lg:flex">
          <a
            href="#masters-lead-capture-form"
            className="bg-primary-500 text-white py-2 px-4 mr-6 rounded hover:bg-primary-300 w-max"
            onClick={(e) => handleLinkClick(e, "masters-lead-capture-form")}
          >
            Tell Me More
          </a>
          {applyUrl && (
            <a
              href={applyUrl}
              className="border border-primary-500 text-primary-500 py-2 px-4 mr-6 rounded hover:text-white hover:bg-primary-500 w-max"
            >
              Apply Now
            </a>
          )}
        </div>
      </div>
    </nav>
  );
}
