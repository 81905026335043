import CTALink from "@/components/CTA/variants/CtaLink/index";

export default CTALink;

// export default function CTALink({
//   label,
//   url,
//   type = "primary",
//   classname,
//   launch_icon,
// }: Props) {
//   const typeVariant = {
//     primary: "bg-primary hover:bg-primary-hover hover:text-primary-foreground text-white",
//     secondary: "bg-white hover:bg-primary hover:text-white border",
//     brand: "bg-brand hover:bg-brand-hover hover:text-brand-foreground text-white",
//     "outline-primary": "border border-primary bg-transparent hover:bg-primary-hover text-primary hover:text-primary-foreground",
//   };
//   return (
//     <a
//       href={url}
//       className={cn(
//         `text-base items-center justify-center px-4 py-2.5 ${
//           typeVariant[type as keyof typeof typeVariant]
//         }`,
//         classname
//       )}
//     >
//       <span className="inline-flex gap-2 items-center justify-center">
//         {label}
//         {launch_icon === "true" && <Launch />}
//       </span>
//     </a>
//   );
// }
