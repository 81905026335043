"use client";
import React, { MutableRefObject, useRef } from "react";
import type { HeroProps } from "../types";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import InternalNav from "@/components/InternalNav";
import useTruncatedElement from "@/hooks/useTrancatedElement";

const NoImageHero = ({
  title,
  title_override,
  path,
  subnavItems,
  has_breadcrumbs = "true",
  children,
  location,
  html_id,
}: HeroProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isTruncated, isReadingMore, setIsReadingMore } =
    useTruncatedElement(ref);

  const truncateText = isReadingMore ? "Show less" : "Read more";
  return (
    <>
      <div className="fullwidth pb-12 pt-20 md:pt-16 bg-cover bg-center bg-[url(/bg-images/learn-page-header.webp)]">
        <div className="mx-auto lg:container [&>*]:text-white">
          {has_breadcrumbs === "true" && path && (
            <Breadcrumbs variant="light" path={path} />
          )}
          <h1 className="text-learn-hero-heading lg:text-learn-hero-heading-large lg:leading-learn-hero-line-height-large mb-6 font-extrabold tracking-tight">
            {title_override || title}
          </h1>
          <div
            ref={ref}
            className={`break-words [&>ul>li>p]:text-white [&>*]:text-white text-xl ${!isReadingMore && "line-clamp-3"}`}
          >
            {children}
          </div>

          {isTruncated && children && (
            <button onClick={() => setIsReadingMore((prev) => !prev)}>
              {truncateText}
            </button>
          )}
        </div>
      </div>
      <InternalNav
        variant="primary"
        data={subnavItems}
        location={location}
        html_id={html_id}
      />
    </>
  );
};

export default NoImageHero;
