import * as React from "react";
import CTALink from "@/components/CTA/ctaLink";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { NavProps, NavData } from "../types";

export default function Secondary({ data, cta }: NavProps) {
  if (!data) {
    return null;
  }

  const navigation: NavData[] = data;

  return (
    <div className="bg-putty-light text-putty-foreground p-4 flex w-full justify-center sticky top-0 z-50">
      <div
        className={`max-w-[1228px] w-full lg:flex hidden text-sm items-center justify-between`}
      >
        <div>
          {navigation.map((item, index) => (
            <a
              key={index}
              href={item.id}
              className="text-lg mx-6 last:mr-0 capitalize text-foreground hover:text-foreground hover:underline"
            >
              {item.name}
            </a>
          ))}
        </div>
        {cta && (
          <CTALink
            type="brand"
            size="xl"
            className="mr-6"
            label={cta.label}
            url={cta.url}
          />
        )}
      </div>
      <div className="lg:hidden p-4 w-full flex flex-col sm:flex-row items-center justify-between gap-4 [&>button]:bg-white [&>button]:text-lg">
        <Select>
          <SelectTrigger>
            <SelectValue placeholder={navigation[0]?.name} />
          </SelectTrigger>
          <SelectContent>
            {/* Must find id's of selectable items based within the page content */}
            {navigation.map((item, index) => (
              <SelectItem key={index} value={item.id} className="md:mx-2">
                {item.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {cta && (
          <CTALink
            type="brand"
            size="lg"
            className="shrink-0 w-full sm:w-auto"
            label={cta.label}
            url={cta.url}
          />
        )}
      </div>
    </div>
  );
}
